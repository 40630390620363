import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import resurrection_banner from './assets/resurrection_banner.png'
import cover_logo from './assets/clean_cover_logo.png'
import soundcloud_logo from './assets/soundcloud.png'
import spotify_logo from './assets/spotify.png'
import book_us_now from './assets/book_us_now.png'
import Linktree, { CHANNEL_TYPES } from 'linkees'
import reportWebVitals from './reportWebVitals';


const cardItems = [
    {
        title: 'Book us now',
        subtitle: '',
        type: CHANNEL_TYPES.OTHER,
        link: 'mailto:bookings@darkcollective-hc.com',
        image: book_us_now
    },
    {
        title: 'Latest Release',
        subtitle: '',
        type: CHANNEL_TYPES.OTHER,
        link: 'https://lnk.bordertownhc.com/Resurrection',
        image: resurrection_banner
    },
    {
        title: 'Spotify',
        subtitle: '',
        type: CHANNEL_TYPES.OTHER,
        link: 'https://open.spotify.com/artist/5DvusI9t1IGolxilkc00a6?si=vu6rU9hERbq80O1nC3XN7Q',
        image: spotify_logo
    },
    {
        title: 'Instagram',
        subtitle: '',
        type: CHANNEL_TYPES.INSTAGRAM,
        link: 'https://instagram.com/dark_collective_official',
    },
    {
        title: 'YouTube',
        subtitle: '',
        type: CHANNEL_TYPES.YOUTUBE,
        link: 'https://www.youtube.com/@darkcollectivehc',
    },
    {
        title: 'SoundCloud',
        subtitle: '',
        type: CHANNEL_TYPES.OTHER,
        link: 'https://soundcloud.com/dark-collective',
        image: soundcloud_logo
    }
]

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Linktree
        name='Dark Collective'
        cardItems={cardItems}
        headerAvatar={cover_logo}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
